import React from 'react';
import { useDispatch } from "react-redux";

import {
  CREATE_WEB_VISIT,
} from "constants/actions";

function makeid(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires="+d.toUTCString();
  document.cookie = "name=https://www.brydenfinancing.com/;Max-Age=365"
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}


const WebVisit = () => {
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const NewvisitId = makeid(20)

 localStorage.getItem("visitid")
  ? localStorage.getItem("visitid") : 
  localStorage.setItem("visitid", NewvisitId)
 const visitId = localStorage.getItem("visitid")
  
 if (visitId) {
  localStorage.setItem("visitId", visitId);
}
  const gclid = params.get("gclid");
  if (gclid) {
     localStorage.setItem("gclid", gclid);
  }
 

  const pixelid = params.get("pixelid");
  if (pixelid) {
      localStorage.setItem("pixelid", pixelid);
  }

  const mssclid = params.get("mssclid");
  if (mssclid) {
      localStorage.setItem("mssclid", mssclid);
  }

  const utm_source = params.get("utm_source");
  if (utm_source) {
    localStorage.setItem("utm_source", utm_source);
  }

  const utm_medium = params.get("utm_medium");
  if (utm_medium) {
    localStorage.setItem("utm_medium", utm_medium);
  }
  
  const utm_campaign = params.get("utm_campaign");
  if (utm_campaign) {
    localStorage.setItem("utm_campaign", utm_campaign);
  }  
  

  // getMyIp()
  const lead_source_params = {
        webcode:visitId,
        cookie: visitId,
        gclid,
        pixelid,
        mssclid,
        utm_source,
        utm_medium,
        utm_campaign,
      }

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: CREATE_WEB_VISIT, payload: lead_source_params });
  }, [dispatch, lead_source_params]);

  

  if (getCookie('brydenfinancing') !== visitId) {
    setCookie('brydenfinancing', visitId)
  }



  return null
};

export default WebVisit;
