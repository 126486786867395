import React from "react";
import { Link } from "react-router-dom";

// // JavaScript plugin that hides or shows a component based on your scroll
// import Headroom from "headroom.js";
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import "./nav.css";

import config from "_config";
import InventorySelectOne from "utilities/InventorySelectOne";

function WhiteNavbar() {
  return (
    <>
      <Navbar expand="lg" id="navbar-main">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              id="navbar-brand"
              className="logo-image"
              to="/"
              tag={Link}
            >
              <img
                src={require("assets/images/logo/bryden-logo-hex-w512.jpg")}
                height={"60px"}
                // className="img-fluid logo-image"
                alt={config.company}
              />
            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
              {config.company}
            </UncontrolledTooltip>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>

          <Collapse navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink to="/inventory" tag={Link}>
                  Inventory
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink to="/application" tag={Link}>
                  Application
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/contact" tag={Link}>
                  Contact
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default WhiteNavbar;
