import React, {Fragment} from "react";
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect } from "react-router-dom";

import Navbar from "./Navbar";
import FooterBlack from "./FooterBlack.js";
import Footer from "./Footer.js";



import routes from "routes.js";
import "./style.css"
// let ps;
import WebVisit from "utilities/WebVisit"
function Pages() {
  // React.useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(this.refs.fullPages);
  //   }
  // })

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      
        return (
          <Route
            exact
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
      
    });
  };


    return (
      <>
        <Navbar />
      <WebVisit />
            <Switch>
              <Fragment > 
              <div>
                {getRoutes(routes)}
               {/* <Redirect to="/index" /> */}
              </div>
             
             </Fragment> 
               
             
              
              
              </Switch>
            <Footer />
          
      </>
    );
  
}

export default Pages;
