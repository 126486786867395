import React, { Suspense, lazy } from "react";

import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";

// import FbXmlFeed from "Facebook/index"
import * as serviceWorker from "./serviceWorkerRegistration";
import { Toast, ToastBody, ToastHeader, Spinner, Button } from "reactstrap";
import "app.css";

import AuthLayout from "Layouts/Auth.jsx";
import ScrollToTop from "utilities/ScrollToTop";

const hist = createBrowserHistory();

const App = () => {
  const [newVersionAvailable, setNewVersionAvailable] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState({});
  const [testNew, setTestNew] = React.useState(true);

  const updateServiceWorker = () => {
    // const { waitingWorker } = this.state;
    waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
    setNewVersionAvailable(false);
    window.location.reload();
  };

  React.useEffect(() => {
    serviceWorker.register({
      onUpdate: (registration) => {
        setWaitingWorker(registration.waiting);
        setNewVersionAvailable(true);
      },
    });
  }, []);
  console.log("newVersionAvailable", newVersionAvailable);
  // if (newVersionAvailable) {
  //   setTimeout(function () {
  //     //Start the timer
  //     updateServiceWorker(); //After 1 second, set render to true
  //   }, 5000);
  // }

  if (newVersionAvailable) {
    return (
      <div className="centered">
        <Toast>
          <ToastHeader icon={<Spinner size="sm" />}>
            New version of Website available
          </ToastHeader>
          <ToastBody className="centered">
            <Button
              size="sm"
              className="reload-text"
              onClick={updateServiceWorker}
            >
              reload
            </Button>
          </ToastBody>
        </Toast>{" "}
      </div>
    );
  } else
    return (
      <Provider store={store}>
        <Router history={hist}>
          <Suspense fallback={<div>Loading...</div>}>
            <ScrollToTop />
            <Switch>
              <Route path="/" render={(props) => <AuthLayout {...props} />} />
            </Switch>
          </Suspense>
        </Router>
      </Provider>
    );
};

export default App;
