import React from "react";
import styled, { keyframes } from "styled-components";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  Input,
  Row,
  Col,
  Label,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import { carBodyTypeLoad } from "sagas/YearMakeModel";

const Thanks = () => {
  return (
    <>
      <Card className="text-dark card-plain ">
        <CardBody>
          <CardTitle className="text-center" tag="h3">
            Thank You for Submitting
          </CardTitle>
        </CardBody>
      </Card>
    </>
  );
};

export default Thanks;
