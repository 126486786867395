import React from "react";

import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { SG_UPDATE_FINANCE_APPLICATION } from "constants/actions";

import PromoHeader from "./PromoHeader";
import Budget from "./Budget";
import LenderImages from "./LenderImages";
import Steps from "./3Steps";

import LeadSource from "utilities/LeadSource";
import ContactInfo from "./Steps/ContactInfo";
import CreditCheck from "./Steps/CreditCheck";
import Residence from "./Steps/Residence";
import Employment from "./Steps/Employment";
import TradeIn from "./Steps/TradeIn";
import Thanks from "./Steps/Thanks";
import ProductApplyWide from "Promotion/Steps/ProductApplyWide";

import Fade from "react-reveal/Fade";

import TransitionGroup from "react-transition-group/TransitionGroup";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

const Promotion = () => {
  const dispatch = useDispatch();
  document.documentElement.classList.remove("nav-open");
  const [inSection, setInSection] = React.useState(1);
  const [application, setApplication] = React.useState(false);
  const [selectedBudget, setSelectedBudget] = React.useState(false);
  const [direction, setDirection] = React.useState(true);
  const [inventorySelected, setInventorySelected] = React.useState(false);

  const _vehcile = 1;
  const _budget = 2;
  const _inventory = 2;
  const _contact = 3;
  const _employment = 4;
  const _residence = 5;
  const _credit = 6;
  const _tradein = 7;
  const _thanks = 8;

  const { get_application, get_applied } = useSelector(
    (state) => ({
      get_application: state.application.application,
      get_applied: state.inventories.applied,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    setApplication(get_application);
  }, [get_application]);

  React.useEffect(() => {
    setInventorySelected(get_applied);
    if (get_applied?.id) {
      setInSection(3)
    }
  }, [get_applied]);

  console.log(get_application);
  console.log(get_applied);
  console.log(inSection);

  return (
    <>
      <div>
        {/* <TransitionGroup enter exit> */}
        {!inventorySelected?.id && (
          <>
            <Fade
              left={direction}
              right={!direction}
              opposite
              when={inSection === _vehcile}
            >
              {inSection === _vehcile && (
                <PromoHeader
                  setInSection={setInSection}
                  application={application}
                />
              )}
            </Fade>

            <Fade opposite when={inSection === _vehcile}>
              {inSection === _vehcile && (
                <>
                  <Steps />
                  <Container>
                    <LenderImages />
                  </Container>
                </>
              )}
            </Fade>
          </>
        )}

        <Container>
          {!inventorySelected?.id && (
            <Fade
              left={direction}
              right={!direction}
              collapse
              when={inSection === _budget}
            >
              <Budget
                setInSection={setInSection}
                setDirection={setDirection}
                application={application}
              />
            </Fade>
          )}

          {inventorySelected?.id && inSection < 3 && (
            <Fade
              left={direction}
              right={!direction}
              collapse
              when={inSection === _inventory}
            >
              <ProductApplyWide 
              inventorySelected={inventorySelected}
              setInSection={setInSection} />
            </Fade>
          )}

          <Fade
            left={direction}
            right={!direction}
            collapse
            when={inSection === _contact}
          >
            <ContactInfo
              setInSection={setInSection}
              setDirection={setDirection}
            />
          </Fade>

          <Fade
            left={direction}
            right={!direction}
            collapse
            when={inSection === _employment}
          >
            <Employment
              setInSection={setInSection}
              setDirection={setDirection}
            />
          </Fade>

          <Fade
            left={direction}
            right={!direction}
            collapse
            when={inSection === _residence}
          >
            <Residence
              setInSection={setInSection}
              setDirection={setDirection}
            />
          </Fade>

          <Fade
            left={direction}
            right={!direction}
            collapse
            when={inSection === _credit}
          >
            <CreditCheck
              setInSection={setInSection}
              setDirection={setDirection}
            />
          </Fade>

          <Fade
            left={direction}
            right={!direction}
            collapse
            when={inSection === _thanks}
          >
            <Thanks setInSection={setInSection} setDirection={setDirection} />
          </Fade>
        </Container>
        {/* 

          <Steps />
            <LenderImages />*/}

        {/* </TransitionGroup>  */}
      </div>
    </>
  );
};

export default Promotion;
