import {
  GET_INVENTORIES,
  GET_MORE_INVENTORY,
  FETCH_INVENTORY,
  GET_INVENTORY_SELECT,
  ADD_INVENTORY,
  SELECT_INVENTORY,
  APPLIED_INVENTORY,
  CLEAR_ALL,
  GET_INVENTORY_LIST,
  EDIT_INVENTORY,
  DELETE_INVENTORY,
  GET_INVENTORIES_PENDING,
  GET_INVENTORIES_LOADING,
} from "constants/actions";

const initialState = {
  inventory: [],
  links: [],
  results: [],
  count: [],
  selected: [],
  applied: [],
  page_size: [],
  loading: false,
  pending: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_INVENTORIES_PENDING:
      return {
        ...state,
        pending: true,
        loading: true,
      };
    case GET_INVENTORIES_LOADING:
      return {
        ...state,
        pending: false,
        loading: true,
      };
    case GET_INVENTORIES:
      // console.log(action.payload)
      return {
        ...state,
        results: action.payload.results,
        links: action.payload.links,
        count: action.payload.count,
        page_size: action.payload.page_size,
        loading: false,
        pending: false,
      };

    case GET_MORE_INVENTORY:
      // console.log(...state.results)
      return {
        ...state,
        results: [...state.results, ...action.payload.results],
        links: action.payload.links,
        count: action.payload.count,
        page_size: action.payload.page_size,
        loading: false,
        pending: false,
      };
    case GET_INVENTORY_LIST:
      return {
        ...state,
        inventorylist: action.payload,
        loading: false,
        pending: false,
      };
    case SELECT_INVENTORY:
      return {
        ...state,
        pending: false,
        loading: false,
        selected: action.payload,
      };
      case APPLIED_INVENTORY:
        return {
          ...state,
          pending: false,
          loading: false,
          applied: action.payload,
        };
    case GET_INVENTORY_SELECT:
      return {
        ...state,
        inventoryselect: action.payload,
      };
    case FETCH_INVENTORY:
      return {
        ...state,
        [action.payload.id]: action.payload,
        loading: false,
      };

    case EDIT_INVENTORY:
      return {
        ...state,
        [action.payload.id]: action.payload,
        loading: false,
      };

    case DELETE_INVENTORY:
      return {
        ...state,
        inventories: state.inventories.filter(
          (Inventory) => Inventory.id !== action.payload
        ),
      };
    case ADD_INVENTORY:
      return {
        ...state,
        inventories: [...state.inventories, action.payload],
        loading: false,
      };
    case CLEAR_ALL:
      return {
        ...state,
        inventory: [],
        links: [],
        results: [],
        count: [],
        selected: [],
        applied: [],
        page_size: [],
        loading: false,
        pending: false,
      };
    default:
      return state;
  }
}

// import _ from 'lodash'

// export default (state = {}, action) => {
//     switch (action.type) {
//         case GET_INVENTORIES:
//             // console.log(`Reducer: ${JSON.stringify(state)}`)
//             return { ...state, ..._.mapKeys(action.payload, 'id') }
//         case FETCH_INVENTORY:
//             return { ...state, [action.payload.id]: action.payload };
//         case ADD_INVENTORY:
//             return { ...state, [action.payload.id]: action.payload };
//         case EDIT_INVENTORY:
//             return { ...state, [action.payload.id]: action.payload };
//         case CLEAR_ALL:
//             return { ...state, inventories: [] };
//         case DELETE_INVENTORY:
//             // payload is the id for delete
//             return _.omit(state, action.payload)
//         default:
//             return state;
//     }
// }
