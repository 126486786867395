import { lazy } from "react";

import Error404 from "Pages/Error404.js";
import Error422 from "Pages/Error422.js";
import Error500 from "Pages/Error500.js";

import Team from "About/Team";
import About from "About/index";
import WhyChooseUs from "About/WhyChooseUs";

import Contact from "Contact/index";

import Promotion from "Promotion/index";
// pages
// const CovidScreen = lazy(() => import("CovidScreen/index"))
// const DigitalSign = lazy(() => import("DigitalSign/index"))

// const TestDrive = lazy(() => import("TestDrive"));
// const Application = lazy(() => import("Application/index"));
// const Inventory = lazy(() => import("Inventory/index"));
const InventoryList = lazy(() => import("InventoryList/index"));
// const LandingPage = lazy(() => import("LandingPage/index"));
const DisplayPage = lazy(() => import("InventoryDetail/DisplayPageFull"));

const Terms = lazy(() => import("Home/TermsnConditions"));
const Privacy = lazy(() => import("Home/PrivacyPolicy"));

export const routes = [
  {
    path: "/promotion",
    name: "Promotion",
    component: Promotion,
  },
  {
    path: "/application",
    name: "Credit Application",
    component: Promotion,
  },

  {
    path: "/inventory",
    name: "Inventory",
    component: InventoryList,
  },

  {
    path: "/error-404",
    name: "Error 404",
    component: Error404,
  },
  {
    path: "/error-422",
    name: "Error 422",
    component: Error422,
  },
  {
    path: "/error-500",
    name: "Error 500",
    component: Error500,
  },
  {
    path: "/vehicle/:id/:slug?",
    name: "VDP",
    component: DisplayPage,
  },

  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },

  {
    path: "/about-us",
    name: "About Us",
    component: About,
  },
  {
    path: "/why-choose-us",
    name: "Why Choose Us",
    component: WhyChooseUs,
  },
  //
  {
    path: "/team",
    name: "Team",
    component: Team,
  },
  {
    path: "/",
    name: "Home",
    component: Promotion,
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: Privacy,
  },
  {
    path: "/terms-and-conditions",
    name: "Terms And conditions",
    component: Terms,
  },
];

export default routes;
