import React from "react";
import {Helmet} from "react-helmet";
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import config from "_config";
import "./style.css";
// import ModalContact from "Contact/ModalContact"

const TeamList = [
  {
    name: "Kelly Bryden",
    order: 1,
    designation: "Owner",
    email: "brydenfinancial@eastlink.com",
    phone: null,
    visible: true,
  },
  {
    name: "Rick Walsh",
    order: 2,
    designation: "Sales Manager",
    email: "rjwalsh444@gmail.com",
    phone: null,
    visible: true,
  },
  
  {
    name: "Shakeel Sheikh",
    order: 3,
    designation: "Sales/Finance Manager",
    email: "Shaqsales24@gmail.com",
    phone: null,
    visible: true,
  },
  {
    name: "Dave Evans",
    order: 4,
    designation: "Sales/Finance Manager",
    email: "daveatbryden@gmail.com",
    phone: null,
    visible: true,
  },
  {
    name: "Robert Boudreau",
    order: 6,
    designation: "Sales/Finance Manager",
    email: 'robert@brydenfinancing.com ',
    phone: null,
    visible: true,
  },
  {
    name: "Andrew Wiswells",
    order: 8,
    designation: "Licensed Mechanic",
    email: null,
    phone: null,
    visible: true,
  },
  {
    name: "George Peverill",
    order: 9,
    designation: "IT Manager",
    email: "george@brydenfinancing.com",
    phone: null,
    visible: true,
  },

  {
    name: "Contact Us",
    order: 11,
    designation: null,
    email: "info@brydenfinancing.com",
    phone: "(902)865-4495",
    visible: true,
  },
];

const Team = () => {
  return (
    <>
      <Helmet>
        <title>Bryden Financing Team
          </title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content='Bryden 
        Financing &amp; Auto Sales, 
        your Lower Sackville area bad credit car dealer. 
        Bryden Financing &amp; Auto Sales team' />
          
      </Helmet>
      <hr />

      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-left mb-3" md="12">
            <h1 className="blue-title">Bryden-Team</h1>

            <h2 className="red-sub-title mb-3">
              Meet the {config.company} Team
            </h2>
            <p className="description">
              Our goal is to deliver a professional, stress-free experience to
              all of our customers.
            </p>
          </Col>
        </Row>

        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <Row className="">
              {TeamList.map((p) => {
                return (
                  <Col md="6" lg="3" sm="12" className="mt-3 mb-3" key={p.order}>
                    <div className="div-with-grey-border">
                      <p className="name">{p.name}</p>
                      <p className="desig">{p.designation}</p>
                      <p className="email">{p.email}</p>
                      <p className="desig">{p.phone}</p>
                      {/* <ModalContact contact={p.name} /> */}
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Team;
